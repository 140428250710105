<template>
    <v-container grid-list-xl fluid>
        <v-row>
            <v-col cols="12" sm="6">
                <h1>Roterização e Etiquetas</h1>
            </v-col>
            <v-col cols="12" sm="6">
                <v-btn :color="items && items.length > 0 ? 'grey white--text' : 'success'" width="100%" small @click="openImport = true" v-if="openImport == false">
                    <v-icon> file_upload </v-icon>
                    Importar Rotas
                </v-btn>
            </v-col>
        </v-row>

        <v-divider class="my-4" />

        <v-flex xs12 v-if="openImport == true">
            <Import @close="closeImport" />
        </v-flex>

        <v-flex xs12 v-if="openImport == false && items && items.length > 0">
            <Sticker :items2="items" @refresh="getItems" />
        </v-flex>
        <v-flex xs12 v-else-if="openImport == false">
            <v-alert outlined color="error" prominent border="top" icon="error">
                <strong>Nenhum pacote localizado para etiquetar.</strong>
            </v-alert>
        </v-flex>
    </v-container>
</template>

<script>
import Import from "./Import";
import Sticker from "./Sticker";

export default {
    components: { Import, Sticker },

    data() {
        return {
            openImport: true,
            items: null
        };
    },

    mounted() {
        this.getItems();
    },

    methods: {
        closeImport() {
            this.getItems();
            this.openImport = false;
        },
        getItems() {
            this.$http
                .get(`routing?timezone=0`)
                .then((result) => {
                    this.items = result;
                    if (this.items && this.items.length > 0) {
                        this.openImport = false;
                    }
                })
                .catch(() => (this.items = []));
        }
    }
};
</script>