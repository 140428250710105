<template>
    <v-flex xs12 v-if="isAdmin && sended == false">
        <v-btn @click="sendNEC(type, id, key, volumeCode, checklist)" color="success" width="100%" small>
            <v-icon left>sync</v-icon>Forçar envio NEC
        </v-btn>
        <ConfirmModal confirmText="Confirmar" cancelText="Cancelar" ref="confirm" />
    </v-flex>
</template>

<script>
import { mapGetters } from "vuex";

import ConfirmModal from "@/components/core/ConfirmModal";

export default {
    components: { ConfirmModal },

    props: {
        type: {
            required: true,
        },
        id: {
            required: true,
        },
        key: {
            required: true,
        },
        volumeCode: {
            required: true,
        },
        checklist: {
            required: true,
        },
        sended: {
            required: true,
        },
    },

    mounted() {
        this.isAdmin = this.verifyRole("ADMIN");
    },

    computed: {
        ...mapGetters(["verifyRole"])
    },

    data() {
        return {
            isAdmin: false,
        };
    },

    methods: {
        sendNEC(type, id, key, volumeCode, checklist) {
            this.$refs.confirm.open("", "Deseja mesmo forçar o envio? Ação não poderá ser desfeita.").then((confirm) => {
                if (confirm) {
                    this.$http
                        .patch(`nec/${type}/force/${id}`, { data: { key, checklist, volumeCode } })
                        .then((result) => {
                            this.$eventHub.$emit("msgSuccess", `Enviado com sucesso. (${JSON.stringify(result)})`);
                        }).catch((error) => {
                            this.$eventHub.$emit("msgError", error.message ? error.message : `Erro ao enviar notificação para NEC.`);
                        });
                }
            });
        },
    }
};
</script>