<template>
    <v-container grid-list-xl fluid>
        <v-layout wrap v-if="!showMode">
            <v-flex xs12>
                <h1>Relatório de operação</h1>
                <v-divider class="mb-4" />
            </v-flex>
            <v-flex xs12 sm4 md5>
                <StoreSelect :value="selectedStore" @change="selectedStore = $event" />
            </v-flex>
            <v-flex xs12 sm4 md5>
                <DateInterval @onChange="dates = $event"></DateInterval>
            </v-flex>
            <v-flex xs12 sm4 md2 text-center>
                <v-btn :disabled="processing" color="success" @click="get">Gerar Relatório</v-btn>
            </v-flex>
        </v-layout>
        <v-layout wrap v-else class="d-print-none">
            <v-flex xs12 sm12 text-center>
                <v-btn @click="showMode = false" small color="error">Fechar Relatório<v-icon right dark> cancel </v-icon></v-btn>
            </v-flex>
            <v-flex xs12 sm12 text-center class="d-print-none">
                <DownloadCsv :data="csv" name="relatorio.csv" :labels="csvLabels" delimiter=";">
                    <v-btn color="success">Download em CSV<v-icon right dark> download </v-icon></v-btn>
                </DownloadCsv>
            </v-flex>
        </v-layout>

        <v-progress-linear v-if="processing" indeterminate />

        <div v-if="showMode">
            <v-layout wrap>
                <v-flex xs12 sm6 md6>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ total }}
                            </div>
                            Número de entregas
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
            <v-layout wrap v-for="(i, index) in itemsGrouped" :key="index">
                <v-flex xs12>
                    <h2>
                        {{ i.date }}
                    </h2>
                </v-flex>

                <v-flex xs12 sm12 md12 v-if="i.items && i.items.length > 0">
                    <v-data-table :headers="headers" :items="i.items" :items-per-page="-1" class="elevation-2" hide-default-footer>
                        <template v-slot:[`item.dateIn`]="{ item }">{{ $moment(item.dateIn).format("HH:mm") }}</template>
                        <template v-slot:[`item.dateInLunch`]="{ item }">{{ $moment(item.dateInLunch).format("HH:mm") }}</template>
                        <template v-slot:[`item.dateOutLunch`]="{ item }">{{ $moment(item.dateOutLunch).format("HH:mm") }}</template>
                        <template v-slot:[`item.dateOut`]="{ item }">{{ $moment(item.dateOut).format("HH:mm") }}</template>
                    </v-data-table>
                </v-flex>
            </v-layout>
        </div>
    </v-container>
</template>

<script>
import DownloadCsv from "vue-json-csv";

import StoreSelect from "@/components/shared/StoreSelect";
import DateInterval from "@/components/core/DateInterval";

export default {
    components: { DownloadCsv, StoreSelect, DateInterval },

    data() {
        return {
            showMode: false,
            dates: [],
            processing: false,
            items: [],
            itemsGrouped: [],
            selectedStore: null,
            csvLabels: {
                dateIn: "Data de entrada",
                intervalIn: "Inicio intervalo",
                intervalOut: "Fim intervalo",
                dateOut: "Data de saída",
                expertName: "Nome do Especialista",
                expertCPF: "CPF do Especialista",
                deliveryNumber: "Números de entregas",
                store: "Loja",
            },
        };
    },

    methods: {
        get() {
            if (!this.processing) {
                this.processing = true;

                let query = this.dates.length == 2 ? `?from=${this.dates[0]}&to=${this.dates[1]}` : `?from=${this.dates[0]}&to=${this.dates[0]}`;

                if (this.selectedStore) {
                    query += `&store=${this.selectedStore}`;
                }

                this.$http
                    .get(`checkin/partner-report${query}&timezone=-3`)
                    .then((result) => {
                        const groups = result.reduce((groups, item) => {
                            const date = this.$moment(item.dateIn).format("DD/MM/YYYY"); // item.dateIn.split("T")[0];
                            if (!groups[date]) {
                                groups[date] = [];
                            }
                            groups[date].push(item);
                            return groups;
                        }, {});

                        this.itemsGrouped = Object.keys(groups).map((date) => {
                            return {
                                date,
                                items: groups[date],
                            };
                        });

                        this.items = result;
                        this.processing = false;
                        this.showMode = true;
                    })
                    .catch(() => {
                        this.processing = false;
                    });
            }
        },
    },

    computed: {
        csv() {
            if (this.items.length > 0) {
                return this.items.map((i) => {
                    return {
                        dateIn: i.dateIn ? this.$moment(i.dateIn).format("DD/MM/YYYY HH:mm") : null,
                        intervalIn: i.dateInLunch ? this.$moment(i.dateInLunch).format("DD/MM/YYYY HH:mm") : null,
                        intervalOut: i.dateOutLunch ? this.$moment(i.dateOutLunch).format("DD/MM/YYYY HH:mm") : null,
                        dateOut: i.dateOut ? this.$moment(i.dateOut).format("DD/MM/YYYY HH:mm") : null,
                        expertName: i.expert.name,
                        expertCPF: i.expert.cpf,
                        deliveryNumber: i.deliveryNumber,
                        store: i.store,
                    };
                });
            }

            return null;
        },
        headers() {
            let headers = [
                { text: "Especialista", value: "expert.name", align: "center" },
                { text: "CPF", value: "expert.cpf", align: "center" },
                { text: "Entrada", value: "dateIn", align: "center" },
                { text: "Inicio de Intervalo", value: "dateInLunch", align: "center" },
                { text: "Fim de intervalo", value: "dateOutLunch", align: "center" },
                { text: "Saída", value: "dateOut", align: "center" },
                { text: "Número de entregas", value: "deliveryNumber", align: "center" },
            ];

            if (!this.selectedStore) {
                headers.push({
                    text: "Local",
                    align: "left",
                    value: "store",
                    visibility: false,
                });
            }

            return headers;
        },
        total() {
            return Number.parseFloat(this.items.reduce((sum, i) => (i.deliveryNumber ? sum + i.deliveryNumber : sum), 0)).toFixed(0);
        },
        totalKM() {
            return Number.parseFloat(this.items.reduce((sum, i) => (i.odometerOut && i.odometerIn ? sum + (i.odometerOut - i.odometerIn) : sum), 0)).toFixed(2);
        },
        experts() {
            let obj = new Object();

            for (let i = 0, len = this.items.length; i < len; i++) {
                obj[this.items[i].expert.cpf] = null;
            }

            return Object.keys(obj).length;
        },
        totalExperts() {
            return Number.parseFloat(this.items.length).toFixed(0);
        },
    },
};
</script>