<template>
    <div>
        <v-container grid-list-md text-xs-center fluid>
            <h1>AT's</h1>
            <v-divider class="mb-4" />

            <v-alert border="bottom" colored-border icon="search" :color="items && items.length > 0 ? 'grey' : 'error'" outlined>
                <v-layout wrap>
                    <v-flex xs12 md6>
                        <StoreSelect :value="selectedStore" @change="selectedStore = $event" />
                    </v-flex>
                    <v-flex xs12 md5>
                        <DateInterval today :label="$t('Data')" @onChange="dates = $event" />
                    </v-flex>
                    <v-flex xs12 md1 mt-4 text-center>
                        <v-btn color="success" @click="getItems" small>Buscar</v-btn>
                    </v-flex>
                </v-layout>
            </v-alert>
            <v-layout wrap class="my-12">
                <v-flex xs12>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ total }}
                            </div>
                            Total
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>

        <SimpleTable formclass="AT/form" endpoint="ats" :items="items" @needReload="search" :aditionalColumns="headers" hideNew hideIdColumn :hideEdit="!isAdmin"
            :hideDelete="!isAdmin" :itemsPerPage="-1">
        </SimpleTable>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import StoreSelect from "@/components/shared/StoreSelect";
import DateInterval from "@/components/core/DateInterval";
import SimpleTable from "@/components/core/SimpleTable";

export default {
    components: { StoreSelect, DateInterval, SimpleTable },

    data() {
        return {
            headers: [
                { text: this.$t("Loja"), value: "store", align: "right", sortable: true },
                { text: this.$t("Especialista"), value: "checkin.expert", align: "right", sortable: true },
                { text: this.$t("Código"), value: "code", align: "right", sortable: true },
                { text: this.$t("Checkin"), value: "checkin.id", align: "right", sortable: true },
            ],
            isAdmin: false,
            items: [],
            dates: [],
            selectedStore: null,
        };
    },

    mounted() {
        this.isAdmin = this.verifyRole("ADMIN");
        this.getItems();
    },

    methods: {
        search() {
            this.getItems();
        },
        getItems() {
            let query = this.dates.length == 2 ? `?from=${this.dates[0]}&to=${this.dates[1]}` : `?from=${this.dates[0]}&to=${this.dates[0]}`;

            if (this.selectedStore) {
                query += `&store=${this.selectedStore}`;
            }

            this.$http
                .get(`ats${query}&timezone=-3`)
                .then((result) => (this.items = result))
                .catch(() => (this.items = []));
        },
    },

    computed: {
        ...mapGetters(["verifyRole"]),
        total() {
            return Number.parseFloat(this.items ? this.items.length : 0).toFixed(0);
        },
    },
};
</script>