<template>
    <v-container grid-list-xl fluid>
        <v-flex xs12 v-if="items && items.length">
            <v-alert outlined color="error" prominent border="top" icon="error">
                <v-row class="my-2 ml-2">
                    <strong>Valide o nome de todas as rotas antes de continuar.</strong>
                    <v-spacer />
                    <v-btn small class="mr-4" color="success" @click="sendRoutes">
                        <v-icon left>check_circle</v-icon>
                        Confirmar rotas e iniciar bipagem
                    </v-btn>
                    <v-btn small color="error" @click="close">
                        <v-icon left>cancel</v-icon>
                        Cancelar
                    </v-btn>
                </v-row>
                <v-row class="ml-2">
                    Você pode apagar as rotas que não deseja importar, ou editar o nome das rotas.
                </v-row>
            </v-alert>

            <v-divider class="mb-4" />

            <v-alert outlined color="black" prominent border="top" icon="error" v-if="errorList && errorList.length > 0">
                <v-list dense v-for="(item, i) in errorList" :key="i">
                    Pedido não localizado: "{{ item.code }}", não atribuido rota "{{ item.route }}"
                </v-list>
            </v-alert>

            <div class="list-group-item" v-for="(route, index) in routes" :key="index">
                <v-list-item class="list-group-item" dense>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ route.name }} - {{ route.total }}
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="route.total > 0">
                        <v-row>
                            <v-btn x-small @click="editRoute(index)">
                                <v-icon left x-small>edit</v-icon>
                                Editar
                            </v-btn>
                            <v-btn x-small class="ml-2" color="error" @click="deleteRoute(index)">
                                <v-icon left x-small>delete</v-icon>
                                Deletar
                            </v-btn>
                        </v-row>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="index" />
            </div>

            <v-divider class="my-4" />

            <v-text-field :label="$t('Filtrar...')" v-model="filterName" clearable />

            <v-data-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :headers="headers" :items="listWithFilter" class="elevation-1 mb-12">
                <template v-slot:item.bippedAt="{ item }">
                    {{ item.bippedAt ? $moment(item.bippedAt).format("DD/MM/YYYY HH:mm") : null }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-chip v-if="item.bipped == false" class="ma-0" outlined color="grey"> Aguardando bipagem </v-chip>
                    <v-btn v-else x-small color="success" @click="print(item)">Gerar Etiqueta</v-btn>
                </template>
            </v-data-table>
        </v-flex>
        <v-flex xs12 v-else>
            <v-file-input label="Carregue o arquivo de rotas" @change="handleFileUpload" accept=".xlsx, .xls, .csv" outlined prepend-icon="mdi-file-excel" />
            <v-alert outlined color="info" prominent border="top" icon="school">
                <strong>Arquivo no padrão do VUUPT</strong>
            </v-alert>
        </v-flex>

        <ConfirmModal confirmText="Confirmar" cancelText="Cancelar" ref="confirm" />
        <EditRoute :itemToEdit="routeToEdit" :show="showEditRoute" @close="closeEditRoute" @change="changeRoute" v-if="routeToEdit" />
    </v-container>
</template>

<script>
import * as XLSX from 'xlsx';

import ConfirmModal from "@/components/core/ConfirmModal";
import EditRoute from "./EditRoute";

export default {
    components: { ConfirmModal, EditRoute },

    data() {
        return {
            routeToEdit: null,
            routeToEditIndex: null,
            showEditRoute: false,
            item: null,
            errorList: null,
            sortBy: ['route', 'order'],
            sortDesc: false,
            filterName: null,
            items: [],
            routes: [],
            headers: [
                { text: this.$t("Rota"), value: "route", align: "left" },
                { text: this.$t("Ordem"), value: "order", align: "left" },
                { text: this.$t("Código de barra"), value: "code", align: "left" },
                { text: this.$t("Cliente"), value: "client", align: "left" },
            ],
        };
    },

    computed: {
        listWithFilter() {
            let ret = this.items;
            if (this.filterName) {
                let exp = new RegExp(this.filterName.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, ""), "i");
                ret = ret.filter((item) =>
                    typeof item === "object" &&
                    item !== null &&
                    exp.test(JSON.stringify(Object.values(item)).normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                );
            }
            return ret;
        },
    },

    methods: {
        sendRoutes() {
            let parameterToSend = this.items.reduce((acc, item) => {
                if (!acc[item.code]) {
                    acc[item.code] = {
                        route: item.route,
                        order: item.order
                    };
                }
                return acc;
            }, {});

            if (parameterToSend.length == 0) {
                this.$eventHub.$emit("msgError", "Nenhuma rota para confirmar.");
                return;
            }
            this.$refs.confirm.open("", "Deseja realmente confirmar as rotas?").then((confirm) => {
                if (confirm) {
                    this.$http
                        .patch(`routing`, { data: parameterToSend })
                        .then((result) => {
                            if (result && result.items && Object.keys(result.items).length > 0) {

                                const error = Object.entries(result.items).filter(([, value]) => value.validated != true);

                                this.errorList = error.reduce((acc, [key, value]) => {
                                    if (!acc) {
                                        acc = [];
                                    }
                                    acc.push({ code: key, ...value });
                                    return acc;
                                }, []);

                                if (error.length > 0) {
                                    this.$eventHub.$emit("msgError", "Existem pacotes que não foram localizadas para roterizar.");
                                } else {
                                    this.$eventHub.$emit("msgSuccess", "Rotas confirmadas com sucesso.");
                                    this.close();
                                }
                            } else {
                                this.$eventHub.$emit("msgError", "Erro na confirmação das rotas.");
                            }
                        }).catch((error) => {
                            this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao confirmar rotas.");
                        });
                }
            });
        },
        close() {
            this.item = null;
            this.errorList = null;
            this.filterName = null;

            this.$emit("close");
        },
        deleteRoute(index) {
            this.$refs.confirm.open("", "Deseja realmente deletar essa rota da importação?").then((confirm) => {
                if (confirm) {
                    this.routes.splice(index, 1);
                    this.items = this.items.filter((item) => item.index != index);
                }
            });
        },
        editRoute(index) {
            this.routeToEditIndex = index;
            this.routeToEdit = this.routes[index];
            this.showEditRoute = true;
        },
        changeRoute(newRoute) {
            let error = false;
            this.routes.forEach((route, index) => {
                if (this.routeToEditIndex != index && route.name == newRoute.name) {
                    this.$eventHub.$emit("msgError", "Rota já existente.");
                    error = true;
                    return;
                }
            });
            if (error == false) {
                this.routes[this.routeToEditIndex].name = newRoute.name;

                this.items.forEach((item) => {
                    if (item.index == this.routeToEditIndex) {
                        item.route = newRoute.name;
                    }
                });

                this.routeToEdit = null;
                this.routeToEditIndex = null;
                this.showDetailsDialog = false;
            }
        },
        closeEditRoute() {
            this.routeToEdit = null;
            this.routeToEditIndex = null;
            this.showDetailsDialog = false;
        },
        handleFileUpload(file) {
            if (file) {
                const extension = file.name.split('.').pop();
                if (extension == "xls" || extension == "csv" || extension == "xlsx") {
                    try {
                        this.items = [];
                        this.routes = [];
                        this.errorList = null;
                        const reader = new FileReader();
                        reader.onload = async (e) => {
                            const data = new Uint8Array(e.target.result);
                            const workbook = XLSX.read(data, { type: 'array', raw: true });

                            let canStartReadLines = false;
                            let indexCode = undefined;
                            let indexPackage = undefined;
                            let indexDestination = undefined;

                            let route = undefined;
                            let routeCounter = 0;

                            await workbook.SheetNames.forEach(async (sheetName) => {
                                const worksheet = workbook.Sheets[sheetName];
                                const sheetDatas = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: true, rawNumbers: true });
                                await sheetDatas.forEach((sheetData) => {
                                    if (sheetData.length > 1) {
                                        const indexRoute = sheetData.indexOf('Agente:');
                                        if (indexRoute >= 0) {
                                            route = sheetData[1];
                                            routeCounter = 0;
                                            canStartReadLines = false;
                                        } else if (sheetData.indexOf('Codigo de Barras') >= 0 && sheetData.indexOf('Destinatário') >= 0 && sheetData.indexOf('Endereço') >= 0) {
                                            canStartReadLines = true;
                                            indexCode = sheetData.indexOf('Codigo de Barras');
                                            indexPackage = sheetData.indexOf('Numero do Pedido');
                                            indexDestination = sheetData.indexOf('Destinatário');
                                        } else if (canStartReadLines == true) {
                                            if (sheetData.length < indexDestination || sheetData.length < indexCode || sheetData.length < indexPackage) {

                                                this.routes.push({
                                                    name: route,
                                                    total: routeCounter,
                                                })

                                                canStartReadLines = false;
                                                route = "";
                                                routeCounter = 0;
                                            } else {
                                                if (sheetData[indexCode] && sheetData[indexPackage]) {
                                                    routeCounter++;
                                                    console.log(sheetData[indexCode])
                                                    if (sheetData[indexCode] && sheetData[indexCode].toString().includes("-WEB-")) {
                                                        sheetData[indexCode] = sheetData[indexCode].split("-WEB-")[0];
                                                    }

                                                    this.items.push({
                                                        index: this.routes.length,
                                                        code: sheetData[indexCode],
                                                        package: sheetData[indexPackage],
                                                        client: indexDestination >= 0 ? sheetData[indexDestination] : "",
                                                        route: route,
                                                        order: sheetData[0],
                                                        bipped: false,
                                                    })
                                                }
                                            }
                                        }
                                    }
                                });
                            });
                        };
                        reader.readAsArrayBuffer(file);
                    } catch {
                        this.$eventHub.$emit("msgError", "Erro na leitura do arquivo.");
                    }
                } else {
                    this.$eventHub.$emit("msgError", "Arquivo não compatível.");
                }
            }
        }
    }
};
</script>