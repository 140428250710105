<template>
    <v-form ref="form" v-model="valid">
        <v-container>
            <v-layout wrap>
                <v-flex xs12>
                    <v-autocomplete v-model="item.key" :items="fileTypes" :label="$t('Tipo de documento')" :rules="[rules.required]" :disabled="blockEdit" />
                </v-flex>

                <v-flex xs12>
                    <v-text-field :label="$t('Titulo E-mail')" v-model="item.title" :rules="[rules.required]" required :disabled="blockEdit" />
                </v-flex>

                <v-flex xs12>
                    <v-switch v-model="item.isTemplate" inset :label="$t('Template do docusing')" :disabled="blockEdit" />
                </v-flex>

                <v-flex xs12 v-if="item.isTemplate">
                    <v-text-field :label="$t('Identificador do template')" v-model="item.hashTemplate" :rules="[rules.required]" required :disabled="blockEdit" />
                </v-flex>
                <v-flex xs12 v-else>
                    <v-text-field :label="$t('TAG de marcação no documento')" v-model="item.marker" :rules="[rules.required]" required :disabled="blockEdit" />
                </v-flex>

                <v-flex xs12 v-if="blockEdit == false && item.isTemplate == false">
                    <v-file-input label="Escolha um arquivo" v-model="selectedFile" required accept="image/*,application/pdf" prepend-icon="mdi-paperclip"
                        :rules="[rules.required]" />
                    <v-progress-linear v-if="percent > 0 && percent <= 100" v-model="percent" height="25">
                        <strong>{{ Math.ceil(percent) }}%</strong>
                    </v-progress-linear>
                </v-flex>

                <v-flex xs12>
                    <SimpleListAdd :list="item.copies" :canEdit="!blockEdit" />
                </v-flex>

                <v-flex xs12 v-if="!blockEdit">
                    <v-btn :disabled="!valid || loading" color="primary" @click="uploadFile" class="my-2"> Enviar </v-btn>
                </v-flex>

                <v-flex xs12 v-if="item.aws">
                    <v-btn color="primary" @click="downloadFile" class="my-2"> Baixar Arquivo </v-btn>
                </v-flex>
                <v-flex xs12 v-if="blockEdit">
                    <v-text-field v-model="test.name" :label="$t('Nome')" />
                    <v-text-field type="email" v-model="test.email" :rules="[rules.email]" :label="$t('E-mail para teste')" />
                    <v-text-field v-model="test.phone" :label="$t('Telefone')" />
                    <v-btn :disabled="loading" color="error" small @click="sendTest">Fazer teste</v-btn>
                </v-flex>

            </v-layout>
        </v-container>
        <iframe ref="pdfIframe" style="display: none;" />
    </v-form>
</template>

<script>
import SimpleListAdd from "./SimpleListAdd";
import fileTypes from "@/assets/json/fileTypes.json";
import rules from "@/helpers/rules";

export default {
    components: { SimpleListAdd },

    props: {
        itemToEdit: {
            type: Object,
        },
    },

    data() {
        return {
            rules,
            fileTypes,
            valid: true,
            loading: false,
            itemClean: {
                key: "",
                title: "",
                copies: [],
                isTemplate: false,
                hashTemplate: null,
                aws: undefined,
                marker: null,
                enable: true,
            },
            item: { ...this.itemClean },
            selectedFile: null,
            percent: null,
            test: {
                name: "Anderson Rodrigo de Souza",
                email: "anderson_cocao@yahoo.com.br",
                phone: "35991131366"
            },
        };
    },

    computed: {
        blockEdit() {
            return (this.item && this.item.id) ? true : false;
        },
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                        this.item.copies = [];
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    methods: {
        async uploadFile() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                const formData = new FormData();

                Object.entries(this.item).forEach(([key, value]) => {
                    if (key === 'copies') {
                        value.forEach((item, index) => {
                            Object.entries(item).forEach(([itemKey, itemValue]) => {
                                formData.append(`copies[${index}][${itemKey}]`, itemValue);
                            });
                        });
                    } else {
                        formData.append(key, value);
                    }
                });

                if (this.selectedFile) {
                    formData.append("file", this.selectedFile, this.selectedFile.name);
                }
                this.$http
                    .postFile("files", formData, {
                        onUploadProgress: (progressEvent) => {
                            this.percent = (progressEvent.loaded / progressEvent.total) * 100;
                            console.log(`Progresso: ${this.percent}%`);
                        },
                    })
                    .then((result) => {
                        if (result) {
                            this.$eventHub.$emit("msgSuccess", "Arquivo carregado com sucesso.");
                            this.$emit("close");
                        } else {
                            this.$eventHub.$emit("msgError", "Erro ao processar arquivo");
                        }
                    })
                    .catch((error) => {
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao carregar arquivo");
                    }).finally(() => {
                        this.loading = false;
                    });
            }
        },
        async downloadFile() {
            this.$http.get(`files/link/${this.item.id}`)
                .then((result) => {
                    if (result) {
                        if (result.link) {
                            window.open(result.link, '_blank');
                        }
                        this.$eventHub.$emit("msgSuccess", "Arquivo localizado com sucesso.");
                    } else {
                        this.$eventHub.$emit("msgError", "Erro ao localizar arquivo");
                    }
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao localizar arquivo");
                });
        },
        async sendTest() {
            this.loading = true;
            this.$http.post(`files/test/${this.item.id}`, this.test)
                .then(() => {
                    this.$eventHub.$emit("msgSuccess", "Email enviado com sucesso.");
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar email.");
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>