<template>
    <v-container grid-list v-if="histories && histories.length > 0">
        <v-flex xs12>
            <h3>{{ label }}</h3>
            <v-divider />
        </v-flex>
        <v-flex xs12>
            <div class="list-group-item" v-for="(history, index) in histories" :key="index">
                <v-list-item class="list-group-item" dense>
                    <v-list-item-content>
                        <v-list-item-title>{{ history.code }} - {{ history.extraCode }} </v-list-item-title>
                        <v-list-item-subtitle> {{ $moment(history.createdAt).format("DD/MM/YYYY HH:mm") }} - {{ history.store }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn v-if="history.ordersNumberOnFinish >= 0" x-small color="success" @click="generateReport(history.id)">{{ $t("Romaneio") }}</v-btn>
                        <v-btn v-else x-small color="warning" @click="$emit('reopen', history)">{{ $t("Finalizar") }}</v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="index" />
            </div>
        </v-flex>
    </v-container>
</template>

<script>
import checklistReport from "@/pdf/checklistReport";

export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: "Últimas coletas",
        },
    },

    data() {
        return {
            checklistReport,
            histories: null,
        };
    },

    mounted() {
        this.getHistory();
    },

    methods: {
        getHistory() {
            this.$http
                .get(`checklists/${this.type}/my-history`)
                .then((result) => {
                    if (result) {
                        this.histories = result;
                    }
                });
        },
        generateReport(id) {
            this.$http
                .get(`checklists/${id}`)
                .then(async (result) => {
                    if (result) {
                        if (result != undefined) {
                            let doc = await checklistReport.create(result);
                            window.open(doc.output("bloburi", { filename: `romaneio_${id}.pdf` }), "_blank");
                        }
                    }
                });
        }
    },
};
</script>