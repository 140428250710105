<template>
    <v-container fluid>
        <h1>Rastreamento de pacote</h1>
        <v-divider class="my-4" />
        <v-layout wrap v-if="resultMode == false">
            <v-flex xs12  class="pa-2">
                <v-switch v-model="filter.advancedMode" inset :label="$t('Buscas avançadas')" />
            </v-flex>
            <v-flex xs12  class="pa-2" v-if="filter.advancedMode == false">
                <v-text-field :label="$t('Digite')" v-model="filter.ticket" clearable outlined hide-details v-on:keyup.enter="search('ticket')"
                    append-icon="qr_code_scanner" append-outer-icon="mdi-send" @click:append="openReadQRCode('ticket')" @click:append-outer="search('ticket')" />
            </v-flex>

            <v-flex xs12 sm6  class="pa-2" v-if="filter.advancedMode == true">
                <v-text-field :label="$t('Código de barras')" v-model="filter.ticket" clearable outlined hide-details v-on:keyup.enter="search('ticket')"
                    append-icon="qr_code_scanner" append-outer-icon="mdi-send" @click:append="openReadQRCode('ticket')" @click:append-outer="search('ticket')" />
            </v-flex>

            <v-flex xs12 sm6 class="pa-2" v-if="filter.advancedMode == true">
                <v-text-field :label="$t('Código de terceiro')" v-model="filter.code" clearable outlined hide-details v-on:keyup.enter="search('code')"
                    append-icon="qr_code_scanner" append-outer-icon="mdi-send" @click:append="openReadQRCode('code')" @click:append-outer="search('code')" />
            </v-flex>

            <v-flex xs12 sm6 class="pa-2" v-if="filter.advancedMode == true">
                <v-text-field :label="$t('nFe')" v-model="filter.nFe" clearable outlined hide-details v-on:keyup.enter="search('nFe')" append-icon="qr_code_scanner"
                    append-outer-icon="mdi-send" @click:append="openReadQRCode('nFe')" @click:append-outer="search('nFe')" />
            </v-flex>

            <v-flex xs12 sm6 class="pa-2" v-if="filter.advancedMode == true">
                <v-text-field :label="$t('Código do pedido')" v-model="filter.order" clearable outlined hide-details v-on:keyup.enter="search('order')"
                    append-icon="qr_code_scanner" append-outer-icon="mdi-send" @click:append="openReadQRCode('order')" @click:append-outer="search('order')" />
            </v-flex>

            <v-flex xs12 class="pa-2" v-if="filter.advancedMode == true">
                <v-text-field :label="$t('Chave CTE')" v-model="filter.key" clearable outlined hide-details v-on:keyup.enter="search('key')" append-icon="qr_code_scanner"
                    append-outer-icon="mdi-send" @click:append="openReadQRCode('key')" @click:append-outer="search('key')" />
            </v-flex>
            <v-flex xs12 class="pa-2">
                <v-btn @click="search" color="success" width="100%">
                    <v-icon left>search</v-icon>Buscar
                </v-btn>
            </v-flex>
            <v-flex xs12 class="pa-2">
                <v-alert outlined color="info" prominent border="top" icon="school">
                    <strong>Busca de pacote</strong>: <br><br> - A busca leva em consideração todos os campos digitados. <br> - Mais de um pacote pode ser retornado.
                </v-alert>
            </v-flex>
        </v-layout>
        <v-layout wrap v-else>
            <v-flex xs12>
                <v-btn @click="clearResult" color="primary" width="100%" small>
                    <v-icon left>search</v-icon>Fazer outra busca
                </v-btn>
            </v-flex>
        </v-layout>

        <div class="ma-4 text-center" v-if="loaded == false">
            <v-progress-circular size="140" width="20" color="primary" indeterminate />
        </div>
        <v-expansion-panels v-model="panel" v-else class="mt-4">
            <v-expansion-panel v-for="( item, i ) in items" :key="i">
                <v-expansion-panel-header class="grey white--text mb-4">
                    {{ item.ticket ? `Código de barra: ${item.ticket}` : null }}
                    {{ item.key ? `Chave CTE: ${item.key}` : null }}
                    <template v-slot:actions>
                        <v-icon color="white">
                            $expand
                        </v-icon>
                    </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <v-layout wrap class="ma-0 pa-0">
                        <v-flex xs12 class="my-2">
                            <h3 style="color: red">{{ packagesStatus[item.lastStatus.status] }}, {{ $moment(item.lastStatus.date).format("DD/MM/YY HH:mm") }}</h3>
                            <h5 style="color: grey">Último Status</h5>
                            <v-divider />
                        </v-flex>
                        <v-flex xs12 text-center>
                            <v-chip class="ma-0" outlined color="error" v-if="!item.collectedBy">
                                Ainda não coletado
                            </v-chip>
                            <v-chip class="ma-0" outlined color="error" v-if="!item.receivedBy">
                                Ainda não recebido
                            </v-chip>
                        </v-flex>
                        <v-flex xs12 v-if="item.collectedDate && $moment.duration($moment().utc().diff($moment(item.collectedDate))).asDays() >= 4">
                            <v-alert icon="error" prominent text dense type="error">Pedido já foi coletado a mais de 4 dias e deve ser devolvido e não mais ser feito tentativa de
                                entrega.</v-alert>
                        </v-flex>
                        <v-flex xs12>
                            <CompleteInfo :item="item" />
                        </v-flex>
                    </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <ConfirmModal confirmText="Confirmar" cancelText="Cancelar" ref="confirm" />
        <BarCode :show="showQRCode" @close="showQRCode = false" @onRead="readedQRCode" />
    </v-container>
</template>

<script>
import CompleteInfo from "@/components/shared/Package/CompleteInfo";
import ConfirmModal from "@/components/core/ConfirmModal";
import BarCode from "@/components/core/BarCode";
import packagesStatus from "@/assets/json/packagesStatus.json";

export default {
    components: { CompleteInfo, ConfirmModal, BarCode },

    data() {
        return {
            type: null,
            showQRCode: false,
            filter: {
                advancedMode: false,
                ticket: null,
                key: null,
                nFe: null,
                code: null,
                order: null,
            },
            resultMode: false,
            items: null,
            panel: null,
            loaded: true,
            packagesStatus,
        };
    },

    methods: {
        clearResult() {
            this.resultMode = false;
            this.items = null;
        },
        openReadQRCode(type) {
            this.type = type;
            this.showQRCode = true;

        },
        readedQRCode(QRCode) {
            if (this.type != null && this.filter[this.type]) {
                this.filter[this.type] = QRCode;
            }
            this.search();
        },
        async forceGetPack(type) {
            if (type != undefined) {
                if (this.ticket != null || this.filter[type]) {
                    this.$refs.confirm.open("", "Deseja forçar uma busca desse pacote na integração do NEC?").then((confirm) => {
                        if (confirm) {
                            this.$http.post(`integrations`, { [type]: this.filter[type] })
                                .then(() => {
                                    this.$eventHub.$emit("msgSuccess", "Pacote integrado com sucesso.");
                                });
                        }
                    });
                }
            }
        },
        async search(type) {
            this.showQRCode = false;
            this.items = null;
            this.panel = null;
            this.loaded = false;

            await this.$http
                .post(`tracking/getByFields`, this.filter)
                .then((result) => {
                    if (result) {
                        this.items = result;
                        if (this.items.length == 1) {
                            this.panel = 0;
                        }
                        if (this.items.length > 0) {
                            this.resultMode = true;
                        }
                    } else {
                        this.$eventHub.$emit("msgError", "Pedido não localizado");
                        this.forceGetPack(type)
                    }
                })
                .catch((e) => {
                    this.$eventHub.$emit("msgError", e.message ? e.message : "Erro ao buscar.");
                    this.forceGetPack(type)
                }).finally(() => this.loaded = true);
        },
    },
};
</script>