<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-container grid-list-xl>
            <v-autocomplete v-model="item.client" :items="clients" item-value="id" item-text="name" :label="$t('Cliente')" @change="getBrands" />
            <v-layout wrap>
                <v-flex xs12>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-switch :label="$t('Habilitado')" v-model="item.enable" />
                            <v-switch :label="$t('Adição de ATs no checkin')" v-model="item.canAddAT" />
                            <v-switch :label="$t('É centro de distribuição')" v-model="item.canReceivePack" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-switch :label="$t('Check-in fora da localização')" v-model="item.canCheckinOutOfCoordinate" />
                            <v-switch :label="$t('Check-out fora da localização')" v-model="item.canCheckoutOutOfCoordinate" />
                        </v-col>
                    </v-row>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-text-field :label="$t('Nome')" v-model="item.name" :rules="[rules.required, rules.min(5)]" />
                </v-flex>
                <v-flex xs12 sm6>
                    <v-text-field label="CNPJ" v-model="item.cnpj" inputmode="numeric" :rules="[rules.cnpj, rules.cnpjValidatorDigit]" v-mask="'##.###.###/####-##'" />
                </v-flex>

                <v-flex xs12 sm6>
                    <v-text-field :label="$t('Coordenadas')" v-model="item.coordinate" :rules="[rules.coordinate]" required />
                </v-flex>
                <v-flex xs12 sm6>
                    <v-text-field :label="$t('Limite de distância para check-in/check-out')" v-model="item.limitDistance" type="number" step="0.01" suffix="km"
                        hint="Deixando vazio o padrão é 0.85km" persistent-hint :rules="[rules.range(0, 50)]" required />
                </v-flex>

                <v-flex xs12>
                    <v-text-field :label="$t('Código de referência')" v-model="item.referenceCode" />
                </v-flex>
                <v-flex xs12 sm6>
                    <v-autocomplete v-model="item.type" :items="storeTypes" :label="$t('Tipo')" clearable />
                </v-flex>
                <v-flex xs12 sm6>
                    <v-autocomplete v-model="item.brands" :items="brands" :label="$t('Marcas')" :disabled="brands == null || brands.length == 0" multiple clearable />
                </v-flex>

                <v-flex xs12 mt-8 elevation-12>
                    <Address :item="item" />
                </v-flex>

                <v-flex xs12>
                    <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">{{ $t("Salvar") }}</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

import storeTypes from "@/assets/json/storeTypes.json";

import Address from "@/components/form/Address";

export default {
    directives: { mask },
    components: { Address },
    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            itemClean: {
                name: "",
                cnpj: "",
                cepOk: true,
                cep: "",
                state: "",
                city: "",
                district: "",
                address: "",
                houseNumber: "",
                complement: "",
                canCheckinOutOfCoordinate: false,
                canCheckoutOutOfCoordinate: false,
                limitDistance: 0.85,
                canAddAT: false,
                enable: true,
            },
            item: { ...this.itemClean },
            clients: null,
            storeTypes,
            brands: null
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                        this.getBrands();
                    }
                });
            },
        },
    },

    methods: {
        getClients() {
            this.$http
                .get(`clients`)
                .then((result) => {
                    if (result) {
                        this.clients = result;
                    }
                })
                .catch(() => {
                    this.clients = null;
                });
        },
        getBrands() {
            if (this.item.client) {
                this.$http
                    .get(`clients/${this.item.client}/brands`)
                    .then((result) => {
                        if (result) {
                            this.brands = result.brands;
                        }
                    })
                    .catch(() => {
                        this.brands = null;
                    });
            } else {
                this.item.brands = null;
                this.brands = null;
            }

        },
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    mounted() {
        this.getClients();
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>