<template>
    <v-container grid-list text-center>
        <v-flex xs12>
            <v-card dark color="primary" v-if="routes == null || routes.length == 0">
                <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card-title>{{ labelNotFound }}</v-card-title>
                </div>
            </v-card>
            <v-layout wrap v-else>
                <v-flex xs12 class="ma-0 pa-0">
                    <p class="font-weight-black">{{ labelSelect }}</p>
                </v-flex>
                <v-flex xs12 class="my-0 py-0">
                    <v-text-field :label="$t('Filtrar...')" v-model="filterName" clearable hide-details/>
                </v-flex>
                <v-flex xs12 v-for="item of listWithFilter" :key="item.id" class="ma-2">
                    <v-btn color="success" class="mx-auto" width="100%" @click="$emit('selected', item)">
                        {{ item.name }}
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-flex>
    </v-container>
</template>

<script>

export default {
    name: "RouteList",

    props: {
        endpoint: {
            type: String,
            required: true,
        },
        labelNotFound: {
            type: String,
            default: "Não foi localizado nenhuma rota.",
        },
        labelSelect: {
            type: String,
            default: "Selecione abaixo a rota:",
        },
    },

    data() {
        return {
            filterName: null,
            routes: [],
        };
    },

    mounted() {
        this.getRoutes();
    },

    computed: {
        listWithFilter() {
            let ret = this.routes;
            if (this.filterName) {
                let exp = new RegExp(this.filterName.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, ""), "i");
                ret = ret.filter(
                    (item) => typeof item === "object" && item !== null && exp.test(JSON.stringify(Object.values(item)).normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                );
            }
            return ret;
        },
    },

    methods: {
        getRoutes() {
            this.$http
                .get(this.endpoint)
                .then((result) => {
                    if (result) {
                        this.routes = result;
                    }
                })
                .catch(() => {
                    this.routes = [];
                });
        },
    },
};
</script>