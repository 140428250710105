<template>
    <v-container grid-list-xl fluid>
        <v-flex xs12 v-if="items && items.length">
            <v-text-field :label="$t('Código de barras')" v-model="ticket" clearable outlined hide-details v-on:keyup.enter="bip()" append-icon="qr_code_scanner"
                append-outer-icon="mdi-send" @click:append="openReadQRCode" @click:append-outer="bip()" />
            <v-switch v-model="printAutomatic" :label="$t('Gerar etiqueta automaticamente ao bipar')" class="my-1 py-0" hide-details dense />
            <v-divider class="mb-2" />

            <v-alert dense class="white--text" color="black" prominent border="left" v-if="lastItem">
                <v-row>
                    <v-col cols="12" md="6">
                        <h2 class="green--text"><strong>Último item bipado:</strong> {{ lastItem.ticket }}</h2>
                        <h2 class="red--text">Rota:<strong> {{ lastItem.route }} ({{ lastItem.routeOrder }}) </strong></h2>
                    </v-col>
                    <v-col cols="12" md="6" v-if="lastItem.client">
                        <strong>Cliente:</strong> {{ lastItem.client.name }}
                        <br>
                        <strong>Endereço:</strong> {{ lastItem.client.address }}
                    </v-col>
                </v-row>
            </v-alert>

            <v-alert outlined color="error" prominent border="top" icon="error" v-if="errorList">
                <p v-html="errorList"></p>
            </v-alert>

            <div class="list-group-item" v-for="(details, route, index) in routes" :key="index">
                <v-list-item class="list-group-item" dense>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ route }} - {{ details.sticked }} / {{ details.counter }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <v-progress-linear height="10" :color="details.sticked == details.counter ? 'success' : 'primary'"
                                :value="details.counter > 0 ? (details.sticked / details.counter) * 100 : 0" />
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider :key="index" />
            </div>

            <v-divider class="my-4" />

            <v-text-field :label="$t('Filtrar...')" v-model="filterName" clearable />

            <v-data-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :headers="headers" :items="listWithFilter" class="elevation-1 mb-12">
                <template v-slot:item.actions="{ item }">
                    <v-chip v-if="!item.stickedBy" class="ma-0" outlined color="grey"> Aguardando bipagem </v-chip>
                    <v-btn v-else-if="item.collectedBy && item.receivedBy" x-small color="success" @click="print(item)">Gerar Etiqueta</v-btn>
                    <v-chip v-else class="ma-0" outlined color="error"> Aguardando recebimento </v-chip>
                </template>
            </v-data-table>
        </v-flex>

        <ConfirmModal confirmText="Confirmar" cancelText="Cancelar" ref="confirm" />
        <BarCode :show="showQRCode" @close="showQRCode = false" @onRead="readedQRCode" />
        <iframe ref="pdfIframe" style="display: none;" />
    </v-container>
</template>

<script>
import ConfirmModal from "@/components/core/ConfirmModal";
import ticketPDF from "@/pdf/ticketPDF";
import BarCode from "@/components/core/BarCode";

export default {
    components: { ConfirmModal, BarCode },

    props: {
        items2: {
            type: Array,
            required: true,
        }
    },

    watch: {
        items2: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.items = [...newVal];
            },
        },
    },

    data() {
        return {
            lastItem: null,
            items: [],
            sortBy: ['route', 'routeOrder'],
            sortDesc: false,
            pdfUrl: null,
            printAutomatic: true,
            ticketPDF,
            ticket: null,
            filterName: null,
            showQRCode: false,
            errorList: null,
            headers: [
                { text: this.$t("Rota"), value: "route", align: "left" },
                { text: this.$t("Ordem"), value: "routeOrder", align: "left" },
                { text: this.$t("Ações"), value: "actions", align: "center" },
                { text: this.$t("Código de barra"), value: "ticket", align: "left" },
                { text: this.$t("Cliente"), value: "client.name", align: "left" },
                { text: this.$t("Endereço"), value: "client.address", align: "left", sortable: false },
            ],
        };
    },

    computed: {
        routes() {
            console.log("process");
            const itemsClone = [...this.items];
            return itemsClone.reduce((acc, item) => {
                const key = item.route;
                if (!acc[key]) {
                    acc[key] = { counter: 0, sticked: 0 };
                }
                acc[key].counter++;
                if (item.stickedBy) {
                    acc[key].sticked++;
                }
                return acc;
            }, {});
        },
        listWithFilter() {
            let ret = this.items;
            if (this.filterName) {
                let exp = new RegExp(this.filterName.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, ""), "i");
                ret = ret.filter((item) =>
                    typeof item === "object" &&
                    item !== null &&
                    exp.test(JSON.stringify(Object.values(item)).normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                );
            }
            return ret;
        },
    },

    methods: {
        addError(i) {
            if (this.errorList == null) {
                this.errorList = "Itens com erro:<br>"
            }
            this.errorList += `<br>${JSON.stringify(i)}`;
        },
        async print(item) {
            let doc = await ticketPDF.create(item);
            const pdfBlob = doc.output("blob");
            const pdfUrl = URL.createObjectURL(pdfBlob);

            const iframe = this.$refs.pdfIframe;
            iframe.src = pdfUrl;
            iframe.onload = () => {
                iframe.contentWindow.print();
                URL.revokeObjectURL(pdfUrl);
            };
        },
        openReadQRCode() {
            this.showQRCode = true;
        },
        readedQRCode(QRCode) {
            this.ticket = QRCode;
        },
        markeBip(item) {
            this.$eventHub.$emit("msgSuccess", `Pacote(${item.ticket}) está na rota (${item.route}), ordem (${item.routeOrder})`);
            this.lastItem = item;
            this.ticket = null;

            if (this.printAutomatic) {
                this.print(item);
            }

        },
        async bipServer(item) {
            await this.$http
                .patch(`routing/bip/${item.id}`)
                .then(() => {
                    this.markeBip(item);
                    this.$emit("refresh");
                }).catch(() => {
                    this.lastItem = null;
                    this.$eventHub.$emit("msgError", "Erro ao dar entrada no pedido.");
                });
        },
        bip() {
            let i = this.items.filter(i => i.ticket == this.ticket)
            if (i && i.length > 0) {
                if (i.length == 1) {
                    if (i[0].collectedBy && i[0].receivedBy) {
                        this.markeBip(i[0]);

                        i[0].stickedBy = "---";

                        this.$set(this.items, 0, { ...this.items[0] }); // Não apagar, força reatividade de uma lista

                    } else {
                        // this.bipServer(i[0]);
                        this.$eventHub.$emit("msgError", "Pacote não pode ser etiquetado pois ainda não foi coletado e recebido.");
                    }
                } else {
                    this.$eventHub.$emit("msgError", `Mais de um pacote com esse código '${this.ticket}'!`);
                    this.ticket = null;
                }
            } else {
                this.$eventHub.$emit("msgError", `Código '${this.ticket}' não localizado`);
                this.ticket = null;
            }
        },
    }
};
</script>