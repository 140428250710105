<template>
    <v-dialog v-model="show" persistent>
        <v-card class="fill-height" fluid>
            <v-toolbar color="success" dark>Detalhes do pedido - {{ item.id }}</v-toolbar>
            <v-card-text class="ma-0 pa-0">
                <CompleteInfo :item="item" />
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-end">
                <v-btn color="error" @click="$emit('close')">Fechar detalhes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import CompleteInfo from "@/components/shared/Package/CompleteInfo";

export default {
    components: { CompleteInfo },

    props: {
        show: {
            type: Boolean,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        }
    },
};
</script>