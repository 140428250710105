<template>
    <v-dialog v-model="show" scrollable persistent>
        <v-card class="fill-height" max-height="90vh" fluid>
            <v-toolbar color="success" dark>Listagem de pacotes</v-toolbar>
            <v-card-text class="ma-0 pa-0">
                <v-container grid-list-xl>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-text-field :label="$t('Nome da Rota')" v-model="item.name" />
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn color="success" @click="$emit('change', item)">Salvar</v-btn>
                <v-btn color="error" @click="$emit('close')">Cancelar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        itemToEdit: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            itemClean: {
                name: "",
                driver: "",
            },
            item: { ...this.itemClean },
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },
};
</script>