<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" sm="6">
                <h1>Trânsito de pacotes</h1>
            </v-col>
            <v-col cols="12" sm="6">
                <DownloadCsv :data="csv" name="relatorio.csv" :labels="csvLabels" delimiter=";">
                    <v-btn color="success" width="100%" small :disabled="!loaded">
                        <v-icon> download </v-icon>
                        Exportar resultado (csv)
                    </v-btn>
                </DownloadCsv>
            </v-col>
        </v-row>

        <v-divider class="mb-4" />

        <v-alert border="bottom" colored-border icon="search" :color="items && items.length > 0 ? 'grey' : 'error'" outlined>
            <v-layout wrap v-if="filterSelecteds != 'nonMoved'">
                <v-flex xs12 md6 xl3>
                    <DateInterval today :label="$t('Data')" @onChange="dates = $event" />
                </v-flex>
                <v-flex xs12 md6 xl3>
                    <UserSelect :value="selectedUser" endpoint="checklists/collect/users" label="Especialista" @change="selectedUser = $event" />
                </v-flex>
                <v-flex xs12 md6 xl3>
                    <StoreSelect :value="selectedStore" endpoint="checklists/report/stores" label="Loja" @change="selectedStore = $event" />
                </v-flex>
                <v-flex xs12 md6 xl3>
                    <StoreSelect :value="selectedStoresDestination" endpoint="checklists/collect-cd/stores" label="Destino" @change="selectedStoresDestination = $event" />
                </v-flex>
            </v-layout>
            <v-layout wrap>
                <v-flex xs12>
                    <v-autocomplete v-model="filterSelecteds" :items="filterOptions" item-text="name" item-value="code" :label="$t('Filtro por pacotes...')" clearable />
                </v-flex>
                <v-flex xs12 text-center>
                    <v-btn color="success" @click="getItems" small :disabled="!loaded">Buscar</v-btn>
                </v-flex>
            </v-layout>
        </v-alert>

        <div class="ma-4 text-center" v-if="loaded == false">
            <v-progress-circular size="140" width="20" color="primary" indeterminate />
        </div>
        <div v-else>
            <v-divider class="mb-4" />
            <v-layout wrap class="ma-12">
                <v-flex xs12 sm6>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ total }}
                            </div>
                            Total de pacotes
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>hourglass_empty</v-icon>
                                {{ waiting }}
                            </div>
                            Aguardando
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>local_shipping</v-icon>
                                {{ collected }}
                            </div>
                            Pacotes Coletados
                        </v-card-text>
                        <v-card-actions v-if="errorNotificationCollected && errorNotificationCollected.length > 0" class="red--text">
                            <v-btn class="ma-2" outlined color="error" small @click="showItems(errorNotificationCollected)">
                                <strong>
                                    {{ errorNotificationCollected.length }} erro(s) de notificação para NEC
                                </strong>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="ma-2" color="error" small @click="forceNEC('collect', errorNotificationCollected)">
                                Forçar envio
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>warehouse</v-icon>
                                {{ received }}
                            </div>
                            Pacotes Recebidos
                        </v-card-text>
                        <v-card-actions v-if="errorNotificationReceived && errorNotificationReceived.length > 0" class="red--text">
                            <v-btn class="ma-2" outlined color="error" small @click="showItems(errorNotificationReceived)">
                                <strong>
                                    {{ errorNotificationReceived.length }} erro(s) de notificação para NEC
                                </strong>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="ma-2" color="error" small @click="forceNEC('receive', errorNotificationReceived)">
                                Forçar envio
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
                <v-flex xs12 v-if="devolution.length > 0">

                    <v-card color="error">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>assignment_return</v-icon>
                                {{ devolution.length }}
                            </div>
                            <div class="d-flex flex-no-wrap justify-space-between">
                                <v-btn class="ma-2" outlined color="white" x-small @click="showItems(devolution)">Devolvidos</v-btn>
                                <DownloadCsv :data="csvDevolution" name="devoluções.csv" :labels="csvLabelsDevolution" delimiter=";">
                                    <v-btn x-small color="success">Exportar</v-btn>
                                </DownloadCsv>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
            <v-divider class="mb-4" />
        </div>

        <SimpleTable endpoint="packages" :items="items" :aditionalColumns="headers" hideNew hideEdit hideDelete>

            <template v-slot:more-btn="item">
                <v-btn class="ma-2" color="info" dark small @click="showDetails(item)">
                    <v-icon dark small>info</v-icon>
                </v-btn>
            </template>
            <template v-slot:extra-column="item">
                <v-chip class="ma-0 chip-small" outlined color="success" v-if="item.collectedBy">
                    Coletado
                </v-chip>
                <v-chip class="ma-0 chip-small" outlined color="error" v-else>
                    Não coletado
                </v-chip>
                <v-chip class="ma-0 chip-small" outlined color="success" v-if="item.receivedBy">
                    Recebido
                </v-chip>
                <v-chip class="ma-0 chip-small" outlined color="error" v-else>
                    Não recebido
                </v-chip>

                <v-chip class="ma-0 chip-large" outlined color="warning" v-if="item.lastStatus && item.lastStatus.status && packagesSpecialStatus[item.lastStatus.status]">
                    {{ packagesSpecialStatus[item.lastStatus.status] }}
                </v-chip>

            </template>
        </SimpleTable>

        <DialogPackageInfo :item="itemDetails" :show="showDetailsDialog" @close="closeItems" v-if="itemDetails" />
        <DialogPackageList :items="itemsToShow" :show="showItemsDialog" @close="closeItems" v-if="itemsToShow" />

    </v-container>
</template>

<script>
import DownloadCsv from "vue-json-csv";
import packagesStatus from "@/assets/json/packagesStatus.json";
import packagesSpecialStatus from "@/assets/json/packagesSpecialStatus.json";
import UserSelect from "@/components/shared/UserSelect";
import StoreSelect from "@/components/shared/StoreSelect";
import DateInterval from "@/components/core/DateInterval";
import SimpleTable from "@/components/core/SimpleTable";
import DialogPackageInfo from "./DialogPackageInfo";
import DialogPackageList from "./DialogPackageList";

export default {
    components: { DateInterval, SimpleTable, DownloadCsv, UserSelect, StoreSelect, DialogPackageInfo, DialogPackageList },

    data() {
        return {
            timer: null,
            loaded: false,
            dates: [],
            items: [],
            itemDetails: null,
            showDetailsDialog: false,
            itemsToShow: null,
            showItemsDialog: false,
            selectedStore: null,
            selectedStoresDestination: null,
            selectedUser: null,
            packagesStatus,
            packagesSpecialStatus,
            headers: [
                { text: this.$t("Último Status"), value: "extra-column", align: "center", sortable: true },
                { text: this.$t("Destino"), value: "storeDestination", align: "right", sortable: false },
                { text: this.$t("Loja"), value: "store", align: "right", sortable: false },
                { text: this.$t("Código de barras"), value: "ticket", align: "right", sortable: false },
                { text: this.$t("Rota"), value: "route", align: "right" },
            ],
            csvLabels: {
                createdAt: "Data importação",
                store: "Loja",
                storeDestination: "Destino",
                ticket: "Pedido",
                key: "Chave CTE",
                nFe: "Nota fiscal",
                volumeCode: "Código Volume",
                collectedDate: "Data coleta",
                collectedBy: "Coletado por",
                checklistCollect: "Romaneio de coleta",
                receivedDate: "Data recebimento",
                receivedBy: "Recebido por",
                checklistReceive: "Romaneio de recebimento",
                sendCollected: "Status NEC coleta",
                sendReceived: "Status NEC recebimento",
                devolution: "Número de devoluções",
                lastStatus: "Último status",
                dateLastStatus: "Data último status",
                clientName: "Nome Cliente",
                clientAddress: "Endereço Cliente",
            },
            csvLabelsDevolution: {
                store: "Loja",
                ticket: "Pedido",
                key: "Chave CTE",
                volumeCode: "Código Volume",
                driver: "Motorista",
                collectedDate: "Data da coleta",
                devolutionAt: "Data da devolução",
                lastStatus: "Último status",
                dateLastStatus: "Data último status",
                details: "Detalhes",
            },
            filterOptions: [
                { "code": "collected", "name": "Coletados" },
                { "code": "received", "name": "Recebidos" },
                { "code": "returned", "name": "Insucessos" },
                { "code": "nonCollected", "name": "Não Coletados (D -1)" },
                { "code": "nonReceived", "name": "Não Recebidos (D -1)" },
                { "code": "nonMoved", "name": "Todos não movimentados (Últimos 3 meses)" },
            ],
            filterSelecteds: null
        };
    },

    mounted() {
        this.getItems();
    },

    computed: {
        csv() {
            if (this.items.length > 0) {
                return this.items.map((i) => {
                    return {
                        createdAt: i.createdAt ? this.$moment(i.createdAt).format("DD/MM/YYYY HH:mm") : null,
                        store: i.store,
                        storeDestination: i.storeDestination,
                        ticket: i.ticket,
                        key: i.key,
                        nFe: i.nFe,
                        volumeCode: i.volumeCode,
                        collectedDate: i.collectedDate ? this.$moment(i.collectedDate).format("DD/MM/YYYY HH:mm") : null,
                        collectedBy: i.collectedBy,
                        checklistCollect: i.checklistCollect,
                        receivedDate: i.receivedDate ? this.$moment(i.receivedDate).format("DD/MM/YYYY HH:mm") : null,
                        receivedBy: i.receivedBy,
                        checklistReceive: i.checklistReceive,
                        // sendCollected: i.sendCollected == true ? "Enviado" : (i.sendCollected == false ? "Erro" : null),
                        // sendReceived: i.sendReceived == true ? "Enviado" : (i.sendReceived == false ? "Erro" : null),
                        lastStatus: i.lastStatus ? packagesStatus[i.lastStatus.status] : null,
                        dateLastStatus: i.lastStatus ? this.$moment(i.lastStatus.date).format("DD/MM/YYYY HH:mm") : null,
                        devolution: i.returnList && i.returnList.length > 0 ? i.returnList.length : null,
                        clientName: i.client && i.client.name ? i.client.name : null,
                        clientAddress: i.client && i.client.address ? i.client.address : null,
                    };
                });
            }

            return [];
        },
        csvDevolution() {
            if (this.items.length > 0) {
                let list = [];
                this.items.filter(i => i.status != null || (i.returnList && i.returnList.length > 0)).forEach((_package) => {
                    let returnPack = false;
                    if (_package.collectedDate && this.$moment.duration(this.$moment().utc().diff(this.$moment(_package.collectedDate))).asDays() >= 4) {
                        returnPack = true;
                    }

                    if (_package.returnList.length > 0) {
                        _package.returnList.forEach((devolution) => {
                            list.push({
                                store: _package.store,
                                ticket: _package.ticket,
                                key: _package.key,
                                volumeCode: _package.volumeCode,
                                collectedDate: _package.collectedDate ? this.$moment(_package.collectedDate).format("DD/MM/YYYY HH:mm") : null,
                                devolutionAt: devolution.date ? this.$moment(devolution.date).format("DD/MM/YYYY HH:mm") : null,
                                driver: devolution.driver,
                                lastStatus: _package.lastStatus ? packagesStatus[_package.lastStatus.status] : null,
                                dateLastStatus: _package.lastStatus ? this.$moment(_package.lastStatus.date).format("DD/MM/YYYY HH:mm") : null,
                                details: returnPack == true ? `O mesmo já foi coletado a mais de 4 dias e deve ser devolvido e não mais ser feito tentativa de entrega.` : null,
                            });
                        });
                    } else {
                        list.push({
                            store: _package.store,
                            ticket: _package.ticket,
                            key: _package.key,
                            volumeCode: _package.volumeCode,
                            collectedDate: _package.collectedDate ? this.$moment(_package.collectedDate).format("DD/MM/YYYY HH:mm") : null,
                            status: _package.status ? packagesStatus[_package.status] : null,
                            details: returnPack == true ? `O mesmo já foi coletado a mais de 4 dias e deve ser devolvido e não mais ser feito tentativa de entrega.` : null,
                        });
                    }
                });
                return list;
            }

            return [];
        },
        total() { return Number.parseFloat(this.items.length).toFixed(0) },
        collected() { return Number.parseFloat(this.items.filter(i => i.collectedDate).length).toFixed(0) },
        received() { return Number.parseFloat(this.items.filter(i => i.receivedDate).length).toFixed(0) },
        devolution() { return this.items.filter(i => i.status != null || (i.returnList && i.returnList.length > 0)) },
        waiting() { return Number.parseFloat(this.items.filter(i => i.collectedDate == undefined && i.receivedDate == undefined).length).toFixed(0) },
        errorNotificationCollected() { return this.items.filter(i => i.collectedDate && i.sendCollected != true) },
        errorNotificationReceived() { return this.items.filter(i => i.receivedDate && i.sendReceived != true) },
    },

    methods: {
        forceNEC(type, items) {
            let itemsToSend = items.map(i => {
                return {
                    id: i.id,
                    key: i.key,
                    volumeCode: i.volumeCode,
                    checklist: type == 'receive' ? i.checklistReceive : i.checklistCollect,
                };
            });
            this.$http
                .patch(`nec/${type}/force`, { data: itemsToSend })
                .then((result) => {
                    this.$eventHub.$emit("msgSuccess", `Enviado com sucesso. (${JSON.stringify(result)})`);
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : `Erro ao enviar notificação para NEC.`);
                });
        },
        showItems(items) {
            this.itemsToShow = items;
            this.showItemsDialog = true;
        },
        closeItems() {
            this.itemsToShow = null;
            this.showDetailsDialog = false;
        },
        showDetails(item) {
            this.itemDetails = item;
            this.showDetailsDialog = true;
        },
        getItems() {
            let query = this.dates.length == 2 ? `?from=${this.dates[0]}&to=${this.dates[1]}` : `?from=${this.dates[0]}&to=${this.dates[0]}`;

            if (this.selectedStore) {
                query += `&store=${this.selectedStore}`;
            }
            if (this.selectedStoresDestination) {
                query += `&storeDestination=${this.selectedStoresDestination}`;
            }
            if (this.selectedUser) {
                query += `&collectedBy=${this.selectedUser}`;
            }
            if (this.filterSelecteds) {
                query += `&moreFilter=${this.filterSelecteds}`;
            }

            this.$http
                .get(`packages${query}&timezone=-3`)
                .then((result) => {
                    this.items = result;
                    this.loaded = true
                })
                .catch((error) => {
                    this.items = [];
                    this.$eventHub.$emit("msgError", error.message ? error.message : `Erro ao enviar buscar informações.`);
                });
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>

<style scoped>
.chip-small {
    width: 110px;
    text-align: center;
    display: inline-block;
}

.chip-large {
    width: 150px;
    text-align: center;
    display: inline-block;
}

.chip-small,
.chip-large>span {
    margin: 0 auto;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>