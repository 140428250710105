<template>
    <v-container grid-list-xl>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
            <v-layout wrap v-if="item">
                <v-flex xs12 mt-4>
                    <StoreSelect :value="item.store" @change="item.store = $event" />
                </v-flex>

                <v-flex xs12 v-if="item.store">
                    <v-card color="primary" class="elevation-12">
                        <v-card-title class="text-center justify-center py-6">
                            <h1 class="font-weight-bold text-h2 white--text">Plano de turnos</h1>
                        </v-card-title>
                        <v-tabs v-model="tab">
                            <v-tabs-slider color="primary"></v-tabs-slider>
                            <v-tab v-for="i in item.plans" :key="i">
                                {{ i.name }}
                            </v-tab>
                            <v-btn color="success" class="align-self-center ml-12" small @click="addPlan">
                                Adicionar Plano
                                <v-icon right small> add_circle </v-icon>
                            </v-btn>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item v-for="(i, index) in item.plans" :key="i" eager>
                                <v-card flat class="ma-12">
                                    <v-layout wrap class="pa-0 ma-0">
                                        <v-flex xs12 class="text-right" pt-0>
                                            <v-btn small color="error" @click="removePlan(index)" outlined>Deletar Plano<v-icon right small> delete </v-icon></v-btn>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-text-field :label="$t('Nome')" v-model="i.name" dense />
                                        </v-flex>

                                        <v-flex xs6>
                                            <v-text-field :label="$t('Números de especialistas')" v-model="i.expertNumber" :rules="[rules.range(1, 100)]" />
                                        </v-flex>
                                        <v-flex xs6> </v-flex>

                                        <v-flex xs2 class="pa-0 ma-0">
                                            <v-switch v-model="i.monday" inset dense label="Segunda" />
                                        </v-flex>
                                        <v-flex xs2 class="pa-0 ma-0">
                                            <v-switch v-model="i.tuesday" inset dense label="Terça" />
                                        </v-flex>
                                        <v-flex xs2 class="pa-0 ma-0">
                                            <v-switch v-model="i.wednesday" inset dense label="Quarta" />
                                        </v-flex>
                                        <v-flex xs2 class="pa-0 ma-0">
                                            <v-switch v-model="i.thursday" inset dense label="Quinta" />
                                        </v-flex>
                                        <v-flex xs2 class="pa-0 ma-0">
                                            <v-switch v-model="i.friday" inset dense label="Sexta" />
                                        </v-flex>
                                        <v-flex xs2 class="pa-0 ma-0"> </v-flex>
                                        <v-flex xs2 class="pa-0 ma-0">
                                            <v-switch v-model="i.saturday" inset dense label="Sábado" />
                                        </v-flex>
                                        <v-flex xs2 class="pa-0 ma-0">
                                            <v-switch v-model="i.sunday" inset dense label="Domingo" />
                                        </v-flex>
                                    </v-layout>
                                    <Daily v-if="i.schedule" :schedule="i.schedule" class="mt-12" />
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-flex>

                <v-flex xs12>
                    <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">Salvar</v-btn>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
import rules from "@/helpers/rules";

import StoreSelect from "@/components/shared/StoreSelect";

import Daily from "./Daily";

const itemClean = {
    store: null,
    plans: [
        {
            name: "Dias Úteis",
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: false,
            sunday: false,
            expertNumber: null,
            schedule: [{ enable: true, start: null, stop: null, interval: 60 }],
        },
        {
            name: "Final de semana",
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: true,
            sunday: true,
            expertNumber: null,
            schedule: [{ enable: true, start: null, stop: null, interval: 60 }],
        },
    ],
};

export default {
    components: { StoreSelect, Daily },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            item: JSON.parse(JSON.stringify(itemClean)),
            tab: null,
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = JSON.parse(JSON.stringify(itemClean));
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    methods: {
        removePlan(index) {
            this.item.plans.splice(index, 1);
        },
        addPlan() {
            this.item.plans.push({
                name: "Plano X",
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
                expertNumber: null,
                schedule: [{ enable: true, start: null, stop: null, interval: 60 }],
            });
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    mounted() {
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>