<template>
    <v-card color="success" height="100%" width="100%">
        <v-card-text class="white--text">
            <v-flex xs12 class="text-center">
                <b>Detalhes</b>
            </v-flex>
            <v-flex xs12 v-if="item.status">
                <b>Status:</b> {{ packagesStatus[item.status] }}
            </v-flex>
            <v-flex xs12 v-if="item.createdBy">
                <b>Criado por:</b> {{ item.createdBy }}
            </v-flex>
            <v-flex xs12 v-if="item.createdAt">
                <b>Criado:</b> {{ $moment(item.createdAt).format("DD/MM/YY HH:mm") }}
            </v-flex>
            <v-flex xs12 v-if="item.updatedAt">
                <b>Última atualização:</b> {{ $moment(item.updatedAt).format("DD/MM/YY HH:mm") }}
            </v-flex>
            <v-flex xs12 v-if="item.ticket">
                <b>Código de barras:</b> {{ item.ticket }}
            </v-flex>
            <v-flex xs12 v-if="item.route">
                <b>Rota:</b> {{ item.route }}
            </v-flex>
            <v-flex xs12 v-if="item.order">
                <b>Pedido:</b> {{ item.order }}
            </v-flex>
            <v-flex xs12 v-if="item.nFe">
                <b>nFe:</b> {{ item.nFe }}
            </v-flex>
            <v-flex xs12 v-if="item.volumeCode">
                <b>Código Volume:</b> {{ item.volumeCode }}
            </v-flex>
            <v-flex xs12 v-if="item.code">
                <b>Código de terceiros:</b> {{ item.code }}
            </v-flex>
        </v-card-text>
    </v-card>
</template>

<script>
import packagesStatus from "@/assets/json/packagesStatus.json";

export default {
    props: {
        item: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            packagesStatus
        };
    },
};
</script>