<template>
    <v-card color="black" width="100%">
        <v-card-text class="white--text">
            <v-flex xs12 class="text-center">
                <b>Histórico</b>
            </v-flex>
            <v-flex xs12 class="text-center" v-for="(history, index) in item.histories" :key="index">
                <b>{{ packagesStatus[history.status] }}</b> pelo usuário <b>{{ history.name }}</b>.
                ({{ $moment(history.date).format("DD/MM/YY HH:mm") }})
            </v-flex>
        </v-card-text>
    </v-card>
</template>

<script>
import packagesStatus from "@/assets/json/packagesStatus.json";

export default {
    props: {
        item: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            packagesStatus
        };
    },
};
</script>