<template>
    <v-card color="primary" class="mx-auto mt-1">
        <v-card-text class="white--text">
            <v-flex xs12 class="text-center">
                <b>Cliente</b>
            </v-flex>
            <v-flex xs12>
                <b>Nome:</b> {{ item.client.name }}
            </v-flex>
            <v-flex xs12>
                <b>Endereço:</b> {{ item.client.address }}
            </v-flex>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        }
    },
};
</script>