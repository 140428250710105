<template>
    <v-card color="success" height="100%" width="100%">
        <v-card-text class="white--text">
            <v-flex xs12 class="text-center">
                <b>Integração NEC</b>
            </v-flex>
            <v-flex xs12 v-if="item.necCreatedAt">
                <b>Criado:</b> {{ $moment(item.necCreatedAt).format("DD/MM/YY HH:mm") }} ({{ item.createMode }})
            </v-flex>
            <v-flex xs12 v-if="item.necUpdatedAt">
                <b>Última atualização:</b> {{ $moment(item.necUpdatedAt).format("DD/MM/YY HH:mm") }}
            </v-flex>
            <v-flex xs12>
                <b>Integração:</b> {{ item.integrationControl }}
            </v-flex>
            <v-flex xs12>
                <b>Chave:</b> {{ item.key }}
            </v-flex>
            <v-flex xs12 v-if="item.idVuupt">
                <b>ID Vuupt:</b> {{ item.idVuupt }}
            </v-flex>
            <v-flex xs12>
                <b>Enviado (coleta):</b> {{ item.sendCollected ? "Sim" : "Não" }}
            </v-flex>
            <v-flex xs12>
                <b>Enviado (recebimento):</b> {{ item.sendReceived ? "Sim" : "Não" }}
            </v-flex>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        }
    },
};
</script>