<template>
    <div>
        <v-card :color="returnItem.sendDevolution ? 'success' : 'error'" width="100%" v-for="(returnItem, index) in item.returnList" :key="index">
            <v-card-text class="white--text">
                <v-flex xs12 class="text-center">
                    <b>Insucesso</b>
                </v-flex>
                <!-- <v-flex xs12>
                <b>Data:</b> {{ $moment(returnItem.date).format("DD/MM/YY HH:mm") }}
            </v-flex>
            <v-flex xs12>
                <b>Recebido por:</b> {{ returnItem.createdBy }}
            </v-flex> -->
                <v-flex xs12>
                    <b>Romaneio:</b> {{ returnItem.checklist }}
                </v-flex>
                <v-flex xs12 v-if="returnItem.driver">
                    <b>Motorista:</b> {{ returnItem.driver }}
                </v-flex>
                <v-flex xs12 v-if="returnItem.driverExternal">
                    <b>Motorista tercerizado:</b> {{ returnItem.driverExternal }}
                </v-flex>
                <v-flex xs12>
                    <b>Enviado (NEC):</b> {{ item.sendDevolution ? "Sim" : "Não" }}
                </v-flex>
                <v-flex xs12>
                    <SendNEC type='return' :id="returnItem._id" :key="item.key" :volumeCode="item.volumeCode" :checklist="returnItem.checklist" :sended="returnItem.sendDevolution" />
                </v-flex>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import SendNEC from "@/components/shared/Package/SendNEC";

export default {
    components: { SendNEC },
    props: {
        item: {
            type: Object,
            required: true,
        }
    },
};
</script>