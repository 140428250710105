<template>
    <v-layout wrap class="ma-1">
        <v-flex xs12 v-if="item.client" class="pa-1">
            <Client :item="item" />
        </v-flex>
        <v-flex xs12 sm6 class="pa-1">
            <Details :item="item" />
        </v-flex>
        <v-flex xs12 sm6 class="pa-1" v-if="item.key">
            <NEC :item="item" />
        </v-flex>
        <v-flex xs12 class="pa-1" v-if="item.histories && item.histories.length > 0">
            <Histories :item="item" />
        </v-flex>
        <v-flex xs12 sm6 class="pa-1" v-if="item.collectedBy">
            <Collected :item="item" />
        </v-flex>
        <v-flex xs12 sm6 class="pa-1" v-if="item.receivedBy">
            <Received :item="item" />
        </v-flex>
        <v-flex xs12 class="pa-1" v-if="item.returnList && item.returnList.length > 0">
            <Return :item="item" />
        </v-flex>
    </v-layout>
</template>

<script>
import Details from "@/components/shared/Package/Details";
import Client from "@/components/shared/Package/Client";
import NEC from "@/components/shared/Package/NEC";
import Histories from "@/components/shared/Package/Histories";
import Return from "@/components/shared/Package/Return";
import Collected from "@/components/shared/Package/Collected";
import Received from "@/components/shared/Package/Received";

export default {
    components: { Details, Client, NEC, Histories, Return, Collected, Received },
    props: {
        item: {
            type: Object,
            required: true,
        }
    },
};
</script>