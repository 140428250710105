<template>
    <v-card :color="item.sendReceived ? 'success' : 'error'" height="100%" width="100%">
        <v-card-text class="white--text">
            <v-flex xs12 class="text-center">
                <b>Recebido</b>
            </v-flex>
            <!-- <v-flex xs12>
                <b>Data:</b> {{ $moment(item.receivedDate).format("DD/MM/YY HH:mm") }}
            </v-flex>
            <v-flex xs12>
                <b>Recebido por:</b> {{ item.receivedBy }}
            </v-flex> -->
            <v-flex xs12>
                <b>Romaneio:</b> {{ item.checklistReceive }}
            </v-flex>
            <v-flex xs12 v-if="item.driverCollectPack">
                <b>Motorista:</b> {{ item.driverCollectPack }}
            </v-flex>
            <v-flex xs12 v-if="item.driverCollectPackExternal">
                <b>Motorista tercerizado:</b> {{ item.driverCollectPackExternal }}
            </v-flex>
            <v-flex xs12>
                <SendNEC type='receive' :id="item.id" :key="item.key" :volumeCode="item.volumeCode" :checklist="item.checklistReceive" :sended="item.sendReceived" />
            </v-flex>
        </v-card-text>
    </v-card>
</template>

<script>
import SendNEC from "@/components/shared/Package/SendNEC";

export default {
    components: { SendNEC },

    props: {
        item: {
            type: Object,
            required: true,
        }
    },
};
</script>