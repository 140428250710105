<template>
    <v-card :color="item.sendCollected ? 'success' : 'error'" height="100%" width="100%">
        <v-card-text class="white--text">
            <v-flex xs12 class="text-center">
                <b>Coletado</b>
            </v-flex>
            <!-- <v-flex xs12>
                <b>Data:</b> {{ $moment(item.collectedDate).format("DD/MM/YY HH:mm") }}
            </v-flex>
            <v-flex xs12>
                <b>Coletado por:</b> {{ item.collectedBy }}
            </v-flex> -->
            <v-flex xs12>
                <b>Romaneio:</b> {{ item.checklistCollect }}
            </v-flex>
            <v-flex xs12>
                <SendNEC type='collect' :id="item.id" :key="item.key" :volumeCode="item.volumeCode" :checklist="item.checklistCollect" :sended="item.sendCollected" />
            </v-flex>
        </v-card-text>
    </v-card>
</template>

<script>
import SendNEC from "@/components/shared/Package/SendNEC";

export default {
    components: { SendNEC },
    props: {
        item: {
            type: Object,
            required: true,
        }
    },
};
</script>